import { debounce } from '../../../../lib/utils/timing'
import TaboolaPosition from './taboola_position'
import { dispatchCustomEvent } from '../../../../lib/utils/events'

const SELECTOR_PLACEMENT = '.m-taboola__placement'

export default (() => {
  class Taboola {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]

      if (window.benji?.isReady) {
        this.init()
      } else {
        window.addEventListener('benji:ready', this.init.bind(this))
      }
    }

    init() {
      if (!window._taboola) {
        window._taboola = []
      }
      this._taboola = window._taboola
      this._addTaboolaEventListeners()

      this.positions = this.elems
        .map(elem => {
          const positionElem = elem.querySelector(SELECTOR_PLACEMENT)
          if (!positionElem) return null
          return TaboolaPosition.fromElement(positionElem)
        })
        .filter(Boolean)

      this.positions.forEach(position => {
        if (position.region === 'index') {
          position.create()
        }
      })

      this._addEventListeners()
      this._addPublicInterface()
      dispatchCustomEvent('Taboola:inited', {})
    }

    createTaboolaPlacement(elem) {
      const placement = TaboolaPosition.fromElement(elem)
      if (!placement) return console.error('[Taboola] error creating placement')
      this.positions.push(placement)
      return placement
    }

    _handleRenderEvent(e) {
      const { detail } = e
      const { container: renderedAd = null, variant } = detail
      // The render event fires even if there is no ad/content, this use case is handled with the _handleNoContentEvent fn
      if (!renderedAd) {
        return
      }

      // ideally taboola should listen to 'nocontent' event in case of abp; but since it is not, we are calling the _handleNoContentEvent fn ourselves
      // if the redneredAd is empty, call the _handleNoContentEvent event
      if (variant === 'abp-mode' || !renderedAd.hasChildNodes()) {
        this._handleNoContentEvent(e)
        return
      }
      this.positions.forEach(position => {
        if (renderedAd === position.elem || position.elem.contains(renderedAd)) {
          position.onRender(detail)
        }
      })
    }

    _handleNoContentEvent(e) {
      const { detail } = e
      const { placement = null } = detail
      this.positions.forEach(position => {
        if (placement === position.placement) {
          position.onNoContent(detail)
        }
      })
    }

    _addTaboolaEventListeners() {
      this._taboola.push({
        listenTo: 'render',
        handler: this._handleRenderEvent.bind(this)
      })

      this._taboola.push({
        listenTo: 'nocontent',
        handler: this._handleNoContentEvent.bind(this)
      })
    }

    _addEventListeners() {
      window.addEventListener('scroll', debounce(this._handleWindowScroll.bind(this), 100))
      this._handleWindowScroll()
    }

    _handleWindowScroll() {
      this.positions.forEach(position => {
        position.handleWindowScroll()
      })
    }

    _addPublicInterface() {
      window.Taboola = {
        createPlacement: this.createTaboolaPlacement.bind(this)
      }
    }
  }

  return Taboola
})()
